import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem, OrgMetricsModel } from 'app/models';
import Store from 'app/stores/Store';

export class OrgMetricsStore extends Store<OrgMetricsModel> {
  @observable public alignMetrics = new ModelItem<OrgMetricsModel>(OrgMetricsModel);
  @observable public reflect360Metrics = new ModelItem<OrgMetricsModel>(OrgMetricsModel);

  constructor() {
    super();
    OrgMetricsModel.setStore(this);
  }

  public async loadAlignMetrics(orgId: number, params: Record<string, any>): Promise<void> {
    const url = ServerRouteHelper.api.organizations.metrics(orgId);
    await this.alignMetrics.load(url, { type: 'align', filters: params });
  }

  public async loadReflect360Metrics(orgId: number, params: Record<string, any>): Promise<void> {
    const url = ServerRouteHelper.api.organizations.metrics(orgId);
    await this.reflect360Metrics.load(url, { type: 'reflect360', filters: params });
  }
}

export default OrgMetricsStore;
