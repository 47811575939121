export const STORE_AUTH = 'authStore';
export const STORE_MEMBER = 'memberStore';
export const STORE_MEMBER_STATE = 'memberStateStore';
export const STORE_PERSPECTIVE = 'perspectiveStore';
export const STORE_TEAM = 'teamStore';
export const STORE_TEAM_REPORT_GROUP = 'teamGroupReportStore';
export const STORE_EXERCISE_TYPE = 'exerciseTypeStore';
export const STORE_SCORE = 'scoreStore';
export const STORE_DISCUSSION_GUIDE = 'discussionGuideStore';
export const STORE_ALIGN_GUIDANCE = 'alignGuidanceStore';
export const STORE_CONTENT_SECTION = 'contentSectionStore';
export const STORE_ALIGN = 'alignStore';
export const STORE_MAIL = 'mailStore';
export const STORE_FEATURE = 'featureStore';
export const STORE_MY360 = 'my360Store';
export const STORE_EXERCISE_REPORT = 'exerciseReportStore';
export const STORE_SINGLE_PLAYER = 'singlePlayerStore';
export const STORE_ROLE = 'roleStore';
export const STORE_ORGANIZATION = 'organizationStore';
export const STORE_SURVEY = 'survey';
export const STORE_ENTITY_STATE = 'entityStateStore';
export const STORE_ENTITY_LABEL = 'entityLabelStore';
export const STORE_EXERCISE_ANSWERS = 'exerciseAnswersStore';
export const STORE_BREAKPOINT = 'breakpointStore';
export const STORE_NAVIGATION = 'navigationStore';
export const STORE_PULSE_FOR_TEAM = 'pulseForTeamStore';
export const STORE_PULSE_FOR_MEMBER = 'pulseForMemberStore';
export const STORE_PULSE_RESPONSE = 'pulseResponseStore';
export const STORE_PULSE_STATEMENT = 'pulseStatementStore';
export const STORE_PULSE_ACTIVITY = 'pulseActivityStore';
export const STORE_PULSE_TEMPLATE_GROUP = 'pulseTemplateGroupStore';
export const STORE_PULSE_REMINDER = 'pulseReminderStore';
export const STORE_PERSONAL_PULSE_REPORT = 'personalPulseReportStore';
export const STORE_DISCUSSION_PROMPT = 'discussionPromptStore';
export const STORE_DISCUSSION_PROMPT_ANSWER = 'discussionPromptAnswerStore';
export const STORE_EXERCISE_COMMITMENT = 'exerciseCommitmentStore';
export const STORE_DISCUSSION_SPACE = 'discussionSpaceStore';
export const STORE_DISCUSSION_SPACE_AGENDA = 'discussionSpaceAgendaStore';
export const STORE_DISCUSSION_SPACE_NOTE = 'discussionSpaceNoteStore';
export const STORE_DISCUSSION_SPACE_VOTE = 'discussionSpaceVoteStore';
export const STORE_DISCUSSION_SPACE_RAISED_HAND = 'discussionSpaceRaisedHandStore';
export const STORE_DISCUSSION_SPACE_WEBSOCKET_STATE = 'discussionSpaceWebsocketStateStore';
export const STORE_ORGANIZATION_REPORT_ROLLUP = 'organizationReportRollupStore';
export const STORE_ORGANIZATION_CONTENT = 'organizationContentStore';
export const STORE_PAIRED_NOTE = 'pairedNoteStore';
export const STORE_MEMBER_RATING = 'memberRatingStore';
export const STORE_ADMIN_STATS = 'adminStatsStore';
export const STORE_ADMIN_ORG_CONTENT = 'adminOrgContentStore';
export const STORE_EMAIL_ACTIVITY = 'emailActivityStore';
export const STORE_PERSPECTIVE_MANAGER_INSIGHTS = 'perspectiveManagerInsightsStore';
export const STORE_COACH = 'coachStore';
export const STORE_ADMIN_PULSE_TEMPLATES = 'adminPulseTemplateStore';
export const STORE_MEMBER_CONFIRMATION = 'memberConfirmationStore';
export const STORE_TEAM_GAME = 'teamGameStore';
export const STORE_TEAM_GAME_PARTICIPANT = 'teamGameParticipantStore';
export const STORE_CSV_UPLOAD = 'csvUploadStore';
export const STORE_PERSPECTIVE_DIMENSION = 'perspectiveDimensionStore';
export const STORE_PERSPECTIVE_DIMENSION_LENS = 'perspectiveDimensionLensStore';
export const STORE_EXERCISE_STATUS = 'exerciseStatusStore';
export const STORE_CUSTOM_LANDING_PAGES_LINK = 'customLandingPagesLinkStore';
export const STORE_PROGRAM = 'programStore';
export const STORE_MY360S_STATS = 'my360sStatsStore';
export const STORE_MEMBER_AVATAR = 'memberAvatarStore';
export const STORE_MBTI_DATA = 'mbtiDataStore';
export const STORE_ORGANIZATION_STATS = 'organizationStatsStore';
export const STORE_PERSPECTIVE_MEMBER = 'perspectiveMemberStore';
export const STORE_COOKIE_PREF = 'cookiePrefStore';
export const STORE_PERSPECTIVE_MEMBER_RESULT = 'perspectiveMemberResultStore';
export const STORE_METABASE = 'metabaseStore';
export const STORE_UI = 'uiStore';
export const STORE_APP_LAYOUT_UI = 'appLayoutUiStore';
export const STORE_MODAL_UI = 'modalsUiStore';
export const STORE_PERSPECTIVE_QUESTION = 'perspectiveQuestionStore';
export const STORE_TEAM_GROUP = 'teamGroupStore';
export const STORE_TEAM_GROUP_DIVISION = 'teamGroupDivisionStore';
export const STORE_MENU = 'menuStore';
export const STORE_ONBOARDING = 'onboardingStore';
export const STORE_PERSPECTIVE_PAIR_SCORE_DIFF = 'perspectivePairScoreDiffStore';
export const STORE_PERSONAL_STATEMENT_WITH_ANSWER = 'personalStatementWithAnswerStore';
export const STORE_PERSPECTIVE_PERSONAL_REPORT = 'perspectivePersonalReportStore';
export const STORE_PERSPECTIVE_TEAMMATE_DIFF = 'perspectiveTeammateDiffStore';
export const STORE_PERSPECTIVE_SCORE_DIFF = 'perspectiveScoreDiffStore';
export const STORE_TEAM_CARD_CHECKLIST = 'teamCardChecklistStore';
export const STORE_ONBOARDING_TOOL_INTRODUCTION = 'onboardingToolIntroductionStore';
export const STORE_CONTRACT = 'contractStore';
export const STORE_ANALYTICS_FILTERS = 'analyticsFiltersStore';
export const STORE_MEMBER_PERSPECTIVE_RESULT = 'memberPerspectiveResultStore';
export const STORE_TEAM_PERSPECTIVE_RESULT = 'teamPerspectiveResultStore';
export const STORE_ORG_NOTICE = 'orgNoticeStore';
export const STORE_SUBSCRIPTION_ORDER = 'subscriptionOrderStore';
export const STORE_TEAM_SUBSCRIPTION_STATUS = 'teamSubscriptionStatusStore';
export const STORE_SUBSCRIPTION_ORDER_ITEM = 'subscriptionOrderItemStore';
export const STORE_SUBSCRIPTION_ITEM = 'subscriptionItemStore';
export const STORE_SUBSCRIPTION_ITEM_PROVIDER = 'subscriptionItemProviderStore';
export const STORE_SUBSCRIPTION_MEMBER = 'subscriptionMemberStore';
export const STORE_SUBSCRIPTION = 'subscriptionStore';
export const STORE_ENTITY_BLOCK = 'entityBlockStore';
export const STORE_SESSION = 'sessionStore';
export const STORE_ENGAGEMENT_ANALYTICS = 'engagementAnalyticsStore';
export const STORE_INTELLIGENCE_ACTION = 'intelligenceActionStore';
export const STORE_PERSPECTIVE_TEAM_DISCUSSION = 'perspectiveTeamDiscussionStore';
export const STORE_INTELLIGENCE_QUESTION = 'intelligenceQuestionStore';
export const STORE_TIMEZONE = 'timezoneStore';
export const STORE_ENGAGE = 'engageStore';
export const STORE_MENU_ITEM = 'menuItemStore';
export const STORE_ORG_METRICS = 'orgMetricsStore';
export const STORE_EXERCISE_TYPE_METRICS = 'exerciseTypeMetricsStore';
