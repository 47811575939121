import { notification } from 'antd';
import Axios, { AxiosRequestConfig, CancelToken, CancelTokenSource } from 'axios';
import URI from 'urijs';

import { HttpRequestMethod } from 'app/constants';
import { getRandomString } from 'app/helpers';
import { ClientDataHelper } from 'app/helpers';
import { URLBuilder } from 'app/helpers/URLBuilder';
import { ErrorCode, ShiftError } from 'app/models/ShiftError';

import ThirdPartyService from './ThirdPartyService';

// @ts-ignore
export interface RequestConfig extends AxiosRequestConfig {
  url: URLBuilder;
  requestId?: string;
  redirectIfUnauthorized?: boolean;
  errorKey?: string;
  throwError?: boolean;
  showGenericError?: boolean;
}

export class ApiService {
  private static instance = new ApiService();
  private requestMap = new Map<string, CancelTokenSource>();
  private unreportedErrors = [
    ErrorCode.UNAUTHORIZED,
    ErrorCode.FORBIDDEN,
    ErrorCode.NOT_FOUND,
    ErrorCode.UNPROCESSABLE_ENTITY,
    ErrorCode.AUTH_TIMEOUT,
  ];

  public static getInstance(): ApiService {
    return this.instance;
  }

  public get(
    url: URLBuilder,
    params?: any,
    headers?: any,
    requestId?: string,
    redirectIfUnauthorized = true,
    errorKey?: string,
    throwError?: boolean,
    showGenericError?: boolean
  ): Promise<any> {
    return this.request({
      method: 'GET',
      url,
      headers,
      params,
      requestId,
      redirectIfUnauthorized,
      errorKey,
      throwError,
      showGenericError,
    });
  }

  public delete(
    url: URLBuilder,
    params?: any,
    headers?: any,
    requestId?: string,
    redirectIfUnauthorized = true,
    errorKey?: string,
    throwError?: boolean,
    showGenericError?: boolean
  ): Promise<any> {
    return this.request({
      method: 'DELETE',
      url,
      headers,
      params,
      requestId,
      redirectIfUnauthorized,
      errorKey,
      throwError,
      showGenericError,
    });
  }

  public post(
    url: URLBuilder,
    data?: any,
    headers?: any,
    params?: any,
    requestId?: string,
    redirectIfUnauthorized = true,
    errorKey?: string
  ): Promise<any> {
    return this.request({
      method: 'POST',
      url,
      data,
      headers,
      params,
      requestId,
      redirectIfUnauthorized,
      errorKey,
    });
  }

  public put(
    url: URLBuilder,
    data?: any,
    headers?: any,
    params?: any,
    requestId?: string,
    redirectIfUnauthorized = true,
    errorKey?: string,
    throwError?: boolean,
    showGenericError?: boolean
  ): Promise<any> {
    return this.request({
      method: 'PUT',
      url,
      data,
      headers,
      params,
      requestId,
      redirectIfUnauthorized,
      errorKey,
      throwError,
      showGenericError,
    });
  }

  public patch(
    url: URLBuilder,
    data?: any,
    headers?: any,
    params?: any,
    requestId?: string,
    redirectIfUnauthorized = true,
    errorKey?: string,
    throwError?: boolean,
    showGenericError?: boolean
  ): Promise<any> {
    return this.request({
      method: 'PATCH',
      url,
      data,
      headers,
      params,
      requestId,
      redirectIfUnauthorized,
      errorKey,
      throwError,
      showGenericError,
    });
  }

  /**
   * These are replacement methods which will simplify APIs.
   */

  public newPost(config: RequestConfig): Promise<any> {
    return this.request({ ...config, method: HttpRequestMethod.POST });
  }

  public newGet(config: RequestConfig): Promise<any> {
    return this.request({ ...config, method: HttpRequestMethod.GET });
  }

  public newDelete(config: RequestConfig): Promise<any> {
    return this.request({ ...config, method: HttpRequestMethod.DELETE });
  }

  public newPut(config: RequestConfig): Promise<any> {
    return this.request({ ...config, method: HttpRequestMethod.PUT });
  }

  public newPatch(config: RequestConfig): Promise<any> {
    return this.request({ ...config, method: HttpRequestMethod.PATCH });
  }

  private async request(config: RequestConfig): Promise<any> {
    const cancelToken = this.addToRequestMap(config.requestId);

    try {
      const { url, headers, ...cleanConfig } = config;

      const response = await Axios.request({
        cancelToken,

        // Some stores passed null instead of undefined making axios override the headers
        // thereby clearing the CSRF token we set on bootstrap.ts,
        // so we short-circuit it to undefined to be safe.
        headers: headers || undefined,

        ...cleanConfig,
        url: url.toString(),
      });
      this.removeFromRequestMap(config.requestId);

      return response?.data;
    } catch (error) {
      // Axios errors comes with toJSON that exposes more context
      const errorContext = error?.toJSON();

      if (this.isCancelError(error, errorContext, config)) {
        return;
      }

      if (this.isRedirectError(error, config)) {
        return;
      }

      const showFieldErrors =
        error?.response?.status === ErrorCode.UNPROCESSABLE_ENTITY ||
        error?.response?.status === ErrorCode.BAD_REQUEST;

      if (showFieldErrors) {
        Object.entries(error?.response?.data?.errors).forEach(([key, value]) => {
          notification.error({
            description: value,
            placement: 'bottomRight',
            duration: 10,
            message: '',
          });
        });
      }

      if (config.showGenericError && !showFieldErrors) {
        Axios.isAxiosError(error) && error.response
          ? this.showErrorMessage(error.response?.statusText)
          : this.showErrorMessage();
      }

      this.reportToSentry(error, errorContext, config);

      if (config.throwError) {
        throw ShiftError.from(error);
      }
    }
  }

  private isCancelError(error, errorContext, config): boolean {
    // Cancel "errors" are not actually errors and should not be treated as such
    // It just means that we have another request that is overriding this request
    if (Axios.isCancel(error) && ClientDataHelper.getSync('is_ci') === false) {
      // Skip Sentry, but log to console so we can see when it happens
      const message = `Request to ${config.url} was cancelled. Suppress if one off, but fix if happening often`;
      console.warn(message);
      return true;
    }

    // Handle when we explicitly cancelled a request using its ID
    if (errorContext?.code === 'ERR_CANCELED') {
      // Skip Sentry, but log to console so we can see when it happens
      const message = `Request to ${config.url} was cancelled`;
      console.warn(message);
      return true;
    }

    // Handle when a browser aborts the request instead of axios canceling a
    // request and replacing it with a new one, possible when a users changes
    // URLs not using history routing
    if (errorContext?.code === 'ECONNABORTED') {
      // Skip Sentry, but log to console so we can see when it happens
      const message = `Request to ${config.url} was aborted`;
      console.warn(message);
      return true;
    }

    return false;
  }

  private isRedirectError(error, config): boolean {
    const errorStatus = error?.response?.status;
    let loginRoute = error?.response?.data?.loginRoute;

    const unAuthWithLoginRoute = errorStatus === ErrorCode.UNAUTHORIZED && loginRoute;
    const authExpired = errorStatus === ErrorCode.AUTH_TIMEOUT;
    const redirectIfUnauthorized = config.redirectIfUnauthorized ?? true;
    const redirectTo = URI().pathname();

    if (authExpired && !loginRoute) {
      loginRoute = '/login';
    }

    if ((unAuthWithLoginRoute || authExpired) && loginRoute && redirectIfUnauthorized) {
      // Check for login loops
      if (loginRoute !== redirectTo) {
        window.location.href = URI(loginRoute).query({ redirect_to: redirectTo });
      }

      return true;
    }

    return false;
  }

  private reportToSentry(error, errorContext, config): void {
    const errorStatus = error?.response?.status;
    const skip = this.unreportedErrors.includes(errorStatus);

    if (skip) {
      return;
    }

    // Dont report cancelled requests to Sentry
    if (this.isCancelError(error, errorContext, config)) {
      return;
    }

    const sentryError = this.ensureSentryError(error);

    ThirdPartyService.sentry.withScope((scope) => {
      const scopeKey = config.errorKey || config.url.key || config.url.toString();
      scope.setFingerprint([config.method, scopeKey, errorStatus ?? 'Unknown']);

      // Override error message with descriptive one
      const url = this.descriptiveKeyForURL(config.url);
      sentryError.message = `Request ${config.method} ${url} failed with message "${sentryError.message}"`;

      // If error comes with more context, set it
      if (errorContext) {
        scope.setContext('Error Context', errorContext);
      }

      ThirdPartyService.sentry.captureException(sentryError);
    });
  }

  private ensureSentryError(error): Error {
    // Are we dealing the an Error object
    if (
      error instanceof Error ||
      error.originalError instanceof Error ||
      error.error instanceof Error
    ) {
      return error.originalError || error.error || error;
    }

    // Are we dealing with normal string
    if (typeof error === 'string') {
      return new Error(error);
    }

    // Fallback
    console.warn('Received a Non-Error exception variable of type:' + typeof error);
    return new Error(JSON.stringify(error));
  }

  private descriptiveKeyForURL(url: URLBuilder) {
    return url.key ? `${url.key} (${url.toString()})` : url.toString();
  }

  private addToRequestMap(requestId?: string): CancelToken {
    if (!requestId) {
      return undefined;
    }

    const source = Axios.CancelToken.source();
    this.requestMap.set(requestId, source);
    return source.token;
  }

  private removeFromRequestMap(requestId?: string) {
    if (!requestId) {
      return;
    }

    this.requestMap.delete(requestId);
  }

  public generateRequsetId(): string {
    return getRandomString();
  }

  /**
   * When request are cancelled, it triggers the applicable request to be abort
   * and throws an exception that needs to be handled.
   *
   * The browser can also cancel requests when the page navigates away.
   *
   * @see this.isCancelError()
   *
   * @param requestId string
   */
  public cancelRequest(requestId: string, requestConfig: RequestConfig): void {
    const source = this.requestMap.get(requestId);

    try {
      source && source.cancel();
    } catch (error) {
      const errorContext = error?.toJSON();

      // Dont report cancelled requests to Sentry
      if (this.isCancelError(error, errorContext, requestConfig)) {
        return;
      }

      console.warn('Failed to cancel request:', error);
    }
  }

  showErrorMessage = (message?: string): void => {
    message = message
      ? `Oops! something went wrong, ${message.toLowerCase()}.`
      : 'Oops, something went wrong loading the data!';

    notification.open({
      message: message,
      description: 'Please re-try your last action or try refreshing the page.',
      placement: 'bottomRight',
    });
  };
}
